import React, { useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import IconExpandArrow from "components/ui/icons/IconExpandArrow";
import styles from "./NavBarExpander.module.scss";
import { useWindowSize } from "components/hooks/useWindowSize";

interface Props {
  className: string;
  isExpanded: boolean;
  onClick: () => void;
}

const NavBarExpander = React.memo(
  ({ isExpanded, onClick, className }: Props) => {
    const { height } = useWindowSize();
    const ref = useRef<HTMLDivElement>(null);
    const [itemHeight, setItemHeight] = useState(-1);
    useLayoutEffect(() => {
      if (ref.current) {
        setItemHeight(ref.current.offsetHeight);
      }
    },[height]);
    const text =
      itemHeight > 200
        ? `Click to ${isExpanded ? "collapse" : "expand"}`
        : itemHeight > 100
        ? isExpanded
          ? "collapse"
          : "expand"
        : "";
    return (
      <div
        className={classNames(styles.expander, className)}
        onClick={onClick}
        ref={ref}
      >
        <div className={styles.expanderInner}>
          <div className={styles.expanderTextWrapper}>
            <span className={styles.expanderText}>{text}</span>
            <IconExpandArrow
              className={classNames([
                styles.expanderIcon,
                { [styles.rotateIcon]: isExpanded },
              ])}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default NavBarExpander;
