import * as React from "react";

const defaultWidth = typeof window !== "undefined" ? window.innerWidth : 600;
const defaultHeight = typeof window !== "undefined" ? window.innerHeight : 900;

function useWindowSize() {
  const [size, setSize] = React.useState({
    width: defaultWidth,
    height: defaultHeight,
  });

  React.useEffect(() => {
    function resizeHandler() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return size;
}

export { useWindowSize };
