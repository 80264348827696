import React from "react"
import 'typeface-koho'
import 'typeface-red-hat-display'
import RootLayout from "./layouts/RootLayout"

const withPageComponents = ({ element }:{element:any}) => {
  return (
      <RootLayout>
        {element}
      </RootLayout>
  )
}

export default withPageComponents
