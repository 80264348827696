import React from "react";
import createIcon from "./createIcon";

const IconDice = createIcon(
  <React.Fragment>
    <path d="M6.58527 10.0438C6.60166 9.99904 6.60919 9.9681 6.61757 9.96229C6.62595 9.95643 6.63491 9.97324 6.66281 10.0281L11.1062 17.294C11.1363 17.352 11.1521 17.4123 11.0287 17.3943L4.49935 16.479C4.36098 16.4542 4.36517 16.4044 4.40235 16.2722L6.58527 10.0438Z" />
    <path d="M11.7999 20.9727L5.82941 17.6827L11.6643 18.4994C11.7439 18.5116 11.7543 18.542 11.7577 18.6164L11.8843 20.923C11.8953 21.0017 11.8773 21.0183 11.7999 20.9727Z" />
    <path d="M12.8575 18.4727L18.9184 17.6589L13.0142 20.9783C12.9252 21.0224 12.9274 20.9967 12.9207 20.9255L12.7911 18.578C12.7919 18.5222 12.7775 18.4841 12.8575 18.4727Z" />
    <path d="M7.64402 9.08115L16.6984 9.05841C16.8144 9.06048 16.7875 9.10203 16.749 9.16163L16.7485 9.16243L12.3316 16.7131C12.2505 16.8309 12.2589 16.8345 12.1812 16.7195L7.58385 9.18181C7.51997 9.07939 7.51465 9.07929 7.64402 9.08115Z" />
    <path d="M4 14.3565L4.20799 8.0368L5.88671 8.77735C5.94449 8.80375 5.94519 8.80783 5.91686 8.88857L4 14.3565Z" />
    <path d="M17.67 10.0383C17.6948 9.99367 17.7034 9.9647 17.7114 9.96147C17.7193 9.95825 17.7264 9.98178 17.7431 10.0414L20.2752 16.2642C20.3118 16.3668 20.315 16.4036 20.2085 16.4271L13.4955 17.3213C13.3843 17.3385 13.3953 17.3113 13.4415 17.2353L17.67 10.0383Z" />
    <path d="M20.513 7.97702C20.5525 7.96089 20.5781 7.95182 20.5943 7.95072C20.6208 7.94889 20.6224 7.97227 20.6271 8.0396L20.6275 8.04437L20.7902 14.5396L18.5268 8.96349C18.5011 8.91207 18.4968 8.87103 18.5659 8.84055L20.513 7.97702Z" />
    <path d="M17.8493 7.90365L14.2537 3.65643L19.9137 6.96111L19.914 6.9613C19.9967 7.01557 20.0137 7.02675 19.8896 7.08112L17.9939 7.92415C17.9092 7.96361 17.8974 7.96039 17.8493 7.90365Z" />
    <path d="M12.2066 3.00004C12.1773 3.00099 12.1435 3.01143 12.125 3.03658L7.97144 7.86187C7.90235 7.9459 7.89766 7.96465 7.99029 7.9655L16.31 7.94416C16.4243 7.93506 16.4248 7.94066 16.3507 7.84663L12.2694 3.02133C12.261 3.00863 12.236 2.99934 12.2066 3.00004Z" />
    <path d="M9.95801 3.9032C9.95633 3.89974 9.92712 3.92096 9.86153 3.96469L4.64148 7.30153L6.27503 8.02162C6.36024 8.04889 6.3857 8.05038 6.44081 7.98356L9.88575 3.98807C9.93423 3.93349 9.95964 3.90665 9.95801 3.9032Z" />
  </React.Fragment>,
  "Dice"
);

export default IconDice;
