import React from "react"
import { Provider } from "react-redux"
import { store } from "../store"

const withRootComponent = ({ element }:{element:any}) => {
  return (
    <Provider store={store}>
        {element}
    </Provider>
  )
}

export default withRootComponent
