import React from "react";
import cx from "classnames";
import styles from "./RuneBackground.module.scss";
import ComplexRune from "components/ui/icons/ComplexRune";

interface Props {
  className?: string;
  layout?: "auth" | "app";
}

const RuneBackground = ({ className, layout = "auth" }: Props) => (
  <div className={cx(styles.root, styles[layout], className)}>
    <ComplexRune className={styles.rune} showGem />
  </div>
);

export default RuneBackground;
