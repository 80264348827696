import React from "react";
import createIcon from "./createIcon";

const IconAnvil = createIcon(
  <React.Fragment>
    <path d="M8.3 6V10.5C11.927 12.723 7.796 14.973 5.6 15.9V18.6H19.1V15.9C13.331 13.443 15.923 9.6 20 8.7V6H8.3ZM2 6.9C2.729 8.817 4.178 10.05 6.5 10.5V6.9H2Z" />
  </React.Fragment>,
  "Anvil"
);

export default IconAnvil;
