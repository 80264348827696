import React from "react";
import createIcon from "./createIcon";

const IconSpells = createIcon(
  <React.Fragment>
    <path d="M8.57291 6.08535L6.43031 7.2852L7.63016 5.1426L6.43031 3L8.57291 4.19986L10.7155 3L9.51565 5.1426L10.7155 7.2852L8.57291 6.08535ZM18.8574 14.4843L21 13.2845L19.8001 15.4271L21 17.5697L18.8574 16.3698L16.7148 17.5697L17.9147 15.4271L16.7148 13.2845L18.8574 14.4843ZM21 3L19.8001 5.1426L21 7.2852L18.8574 6.08535L16.7148 7.2852L17.9147 5.1426L16.7148 3L18.8574 4.19986L21 3ZM13.578 12.2389L15.6692 10.1477L13.8523 8.33079L11.7611 10.422L13.578 12.2389ZM14.4608 7.53375L16.4663 9.53922C16.8005 9.85633 16.8005 10.4134 16.4663 10.7476L6.46459 20.7493C6.13034 21.0836 5.57327 21.0836 5.25616 20.7493L3.25068 18.7438C2.91644 18.4267 2.91644 17.8697 3.25068 17.5354L13.2524 7.53375C13.5866 7.1995 14.1437 7.1995 14.4608 7.53375Z" />{" "}
  </React.Fragment>,
  "Anvil"
);

export default IconSpells;
