import * as React from "react";
import IconHome from "components/ui/icons/IconHome";
import IconDice from "components/ui/icons/IconDice";
import IconQuadrant from "components/ui/icons/IconQuadrant";
import IconBrew from "components/ui/icons/IconBrew";
import IconAnvil from "components/ui/icons/IconAnvil"
import IconSpells from "components/ui/icons/IconSpells"

const ROUTES = {
  HOME: "/",
  CHARACTERS: "/characters",
  HOMEBREW: "/homebrew",
  ITEMS: "/items",
  SPELLS: "/spells",
  FOUNDRY: "/foundry",
};

export interface TSidebarNavItem {
  name: string;
  icon: any;
  to?: string;
  href?: string;
  target?: string;
  requiredRole?: number | number[];
}

export interface TSidebarNavSection {
  sectionName: string;
  items: TSidebarNavItem[];
}

export type TSidebarNav = TSidebarNavSection[];

export const sidebarNav: TSidebarNav = [
  {
    sectionName: "",
    items: [
      {
        name: "Home",
        icon: <IconHome />,
        to: ROUTES.HOME,
      },
      {
        name: "Characters",
        icon: <IconQuadrant />,
        to: ROUTES.CHARACTERS,
      },
      {
        name: "Items",
        icon: <IconAnvil />,
        to: ROUTES.ITEMS,
      },{
        name: "Spells",
        icon: <IconSpells />,
        to: ROUTES.SPELLS,
      },
      {
        name: "Homebrew",
        icon: <IconBrew />,
        to: ROUTES.HOMEBREW,
      },
      {
        name: "Play",
        icon: <IconDice />,
        to: ROUTES.FOUNDRY,
      },
    ],
  },
];
