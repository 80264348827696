import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import Tooltip from "components/ui/tooltip/Tooltip";
import styles from "./NavBar.module.scss";
import { Link } from "gatsby"

interface Props {
  icon: React.ReactNode;
  text: React.ReactNode;
  active: boolean;
  displayTooltip?: boolean;
  to?:string
}
const NavItem = (props: Props) => {
  return (
    <motion.div variants={variants} exit="hidden">
      <Link to={props.to||""} >
        <NavItemWrapper
          content={props.text}
          active={props.active}
          displayTooltip={props.displayTooltip}
        >
          <div className={cx(styles.navItem, props.active && styles.active)}>
            <div className={styles.navItemIcon}>{props.icon}</div>

            <div className={styles.navItemText}>{props.text}</div>
          </div>
        </NavItemWrapper>
      </Link>
    </motion.div>

  );
};

const NavItemWrapper = ({
  content,
  children,
  active,
  displayTooltip,
}: {
  content: React.ReactNode;
  children: React.ReactNode;
  active: boolean;
  displayTooltip?: boolean;
}) => (
  <>
    {displayTooltip ? (
      <Tooltip
        content={content}
        direction={"LEFT_TOP"}
        offset={0}
        className={cx(styles.tooltip, active && styles.active)}
        animation={tooltipAnimations}
        arrow={false}
      >
        {children}
      </Tooltip>
    ) : (
      children
    )}
  </>
);

const variants = {
  visible: {
    pointerEvents:'inherit' as 'inherit',
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  hidden: {
    pointerEvents:'none' as 'none',
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const tooltipAnimations = {
  initial: {
    opacity: 0,
    // scale: 0.8,
    x: -12,
  },
  animate: { opacity: 1, scale: 1, x: 0 },
  exit: {
    opacity: 0,
    // scale: 0.8,
    x: -12,
  },
  transition: {
    type: "tween",
    damping: 20,
    stiffness: 100,
  },
};

export default NavItem;
