import React from "react"

export const EXACT_MATCH_DIM = "exact->string"; //Match a string to source data string
export const EXACT_MATCH_ARRAY_DIM = "exact->array"; //Match a string to one item in source data array
export const FACET_MATCH_DIM = "facet->string"; //Match an facet array of strings to source data string
export const FACET_MATCH_ARRAY_DIM = "facet->array"; //Match an facet array of strings to source data array
export const FUZZY_MATCH_DIM = "fuzzy->string"; //Fuzzy a string to source data string

type IExactMatch = typeof EXACT_MATCH_DIM;
type IExactArrayMatch = typeof EXACT_MATCH_ARRAY_DIM;
type IFacetMatch = typeof FACET_MATCH_DIM;
type IFacetArrayMatch = typeof FACET_MATCH_ARRAY_DIM;
type IFuzzyMatch = typeof FUZZY_MATCH_DIM;

export interface IFacetOption {
  key: string;
  icon?: React.ReactNode;
  label?: string;
  color?: string;
}

export interface IRawDimension {
  key: string;
  label?: string;
  match?:
    | IExactMatch
    | IExactArrayMatch
    | IFuzzyMatch
    | IFacetMatch
    | IFacetArrayMatch;
  options?:IFacetOption[]
}

export type TBucket = string | string[] | null;
export interface IDimension extends IRawDimension {
  bitIndex: number;
  buckets: TBucket;
}

export const getDimensionObject = (dimensions:IDimension[])=>{
  return dimensions.reduce((obj, f) => {
    return { ...obj, [f.key]: f };
  }, {} as Record<string, IDimension>);
}
