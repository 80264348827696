import produce from "immer";
import { createStore as reduxCreateStore, Store } from "redux";
import {
  IAppState as IntAppState,
  IState as IntState,
  initialState,
} from "./state";
import { rootReducer, APP_NEXT_STATE } from "./reducer";

const store: Store<IState> = reduxCreateStore(
  rootReducer,
  typeof window !== "undefined" &&
  // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const appGetState = () => store.getState().app;
const appNextState = (state: IAppState, comment: string) => {
  console.log(comment, state);
  store.dispatch({ state, type: `${APP_NEXT_STATE}: ${comment}` });
};
const appUpdateState = (producer: (s: IAppState) => void, comment: string) => {
  appNextState(
    produce(appGetState(), s => {
      producer(s);
    }),
    comment
  );
};

export { store, initialState, appGetState, appNextState, appUpdateState };
export type IState = IntState;
export type IAppState = IntAppState;
