import React from "react";
import createIcon from "./createIcon";

const IconExpandArrow = createIcon(
  <React.Fragment>
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
    <path fill="none" d="M0 0h24v24H0V0z" />
  </React.Fragment>,
  "EyeHidden"
);

export default IconExpandArrow;
