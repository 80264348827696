import { appGetState, appNextState, appUpdateState } from "store";
import { initialState } from "store/state";

export interface TBreadcrumb {
  label: string;
  url?: string;
}

const ROOT_BREADCRUMB = { label: "Coinstone", url: "/" };
const ROOT_BREADCRUMB_SHORT = { label: "LH", url: "/" };

const comment = (m: string) => `LayoutService::${m}`;

class LayoutService {
  private appGetState = appGetState;
  private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  /**
   */
  public expandSidebarToggle = (isExpanded: boolean) => {
    this.appUpdateState(
      s => (s.layout.isExpanded = isExpanded),
      comment("expandSidebarToggle")
    );
  };

  public expandMobileSidebarToggle = (isMobileOpened: boolean) => {
    this.appUpdateState(
      s => (s.layout.isMobileOpened = isMobileOpened),
      comment("expandMobileSidebarToggle")
    );
  };

  /**
   */
  public setHeaderLarge = (isHeaderLarge: boolean) => {
    this.appUpdateState(
      s => (s.layout.isHeaderLarge = isHeaderLarge),
      comment("setHeaderLarge")
    );
  };

  /**
   */
  public setBreadcrumbs = (
    items: TBreadcrumb[],
    includeRoot: boolean = true
  ) => {
    const root = includeRoot
      ? items.length
        ? ROOT_BREADCRUMB_SHORT
        : ROOT_BREADCRUMB
      : null;
    const breadcrumbs: TBreadcrumb[] = root ? [root, ...items] : [...items];
    this.appUpdateState(
      s => (s.layout.breadcrumbs = breadcrumbs),
      comment("setBreadcrumbs")
    );
  };

  /**
   */
  public confirm = (
    title: string,
    content: any,
    actionLabel: string = "OK",
    action: () => void = () => {},
    cancel: boolean = true
  ) => {
    this.appUpdateState(s => {
      s.layout.dialog = {
        title,
        content,
        actionLabel,
        action,
        cancel,
        open: true,
      };
    }, comment("confirm"));
  };

  /**
   */
  public closeDialog = () => {
    this.appUpdateState(
      s => (s.layout.dialog = { ...initialState.layout.dialog }),
      comment("closeDialog")
    );
  };

  /**
   */
  public showSnackbar = (variant: "success" | "error", message: string) => {
    this.appUpdateState(
      s => (s.layout.snackbar = { variant, message, open: true }),
      comment("showSnackbar")
    );
  };

  /**
   */
  public closeSnackbar = () => {
    this.appUpdateState(
      s => (s.layout.snackbar = { ...initialState.layout.snackbar }),
      comment("closeSnackbar")
    );
  };
  public setRootLayout = (layout: "app"|"auth") => {
    this.appUpdateState(
      s => (s.layout.root = layout),
      comment("setRootLayout")
    );
  };

  public setMobileLayout = (isMobile: boolean) => {
    this.appUpdateState(
      s => (s.layout.isMobileLayout = isMobile),
      comment("setMobileLayout")
    );
  };
}

const layoutService = new LayoutService();
export { layoutService };
