import React from "react";
import cx from "classnames";
import styles from "./BrandName.module.scss";
interface Props {
  className?: string;
}

const CoinstoneLogo = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(styles.root, className)}
  >
    <path d="M21.6198 17.5046C21.975 17.2392 22.4626 17.2395 22.8175 17.5052L26.2702 20.0895C26.5224 20.2783 26.6709 20.575 26.6709 20.8901V25.3532C26.6709 25.6676 26.523 25.9638 26.2715 26.1527L22.8288 28.7389C22.474 29.0054 21.986 29.0063 21.6303 28.741L18.1599 26.1526C17.9069 25.9639 17.7578 25.6667 17.7578 25.351V20.8907C17.7578 20.5753 17.9066 20.2783 18.1594 20.0896L21.6198 17.5046Z" fill="#FFDEB0"/>
    <path d="M21.6198 17.5046C21.975 17.2392 22.4626 17.2395 22.8175 17.5052L26.2702 20.0895C26.5224 20.2783 26.6709 20.575 26.6709 20.8901V25.3532C26.6709 25.6676 26.523 25.9638 26.2715 26.1527L22.8288 28.7389C22.474 29.0054 21.986 29.0063 21.6303 28.741L18.1599 26.1526C17.9069 25.9639 17.7578 25.6667 17.7578 25.351V20.8907C17.7578 20.5753 17.9066 20.2783 18.1594 20.0896L21.6198 17.5046Z" fill="url(#coinstone_0001000_paint0)"/>
    <path d="M26.7432 14.6717C26.7432 14.3566 26.5947 14.06 26.3424 13.8712L22.8783 11.2782C22.5226 11.012 22.034 11.0123 21.6787 11.2791L18.2263 13.8711C17.9747 14.06 17.8267 14.3562 17.8267 14.6708L17.8267 16.7519C17.8267 17.5755 18.7665 18.046 19.4259 17.5525L21.6789 15.866C22.0342 15.6001 22.5222 15.6001 22.8774 15.866L25.1439 17.5626C25.8033 18.0561 26.7432 17.5856 26.7432 16.762L26.7432 14.6717Z" fill="#792C5A"/>
    <path d="M26.7432 31.4523C26.7432 31.7674 26.5947 32.064 26.3424 32.2529L22.8774 34.8465C22.5222 35.1124 22.0342 35.1124 21.6789 34.8465L18.2274 32.263C17.9752 32.0742 17.8267 31.7775 17.8267 31.4624L17.8267 29.3721C17.8267 28.5485 18.7665 28.078 19.4259 28.5716L21.6789 30.258C22.0342 30.5239 22.5222 30.5239 22.8774 30.258L25.1439 28.5614C25.8033 28.0679 26.7432 28.5384 26.7432 29.362L26.7432 31.4523Z" fill="#792C5A"/>
    <path d="M34.5103 15.1282C34.5103 14.3046 35.4502 13.8341 36.1095 14.3277L38.215 15.9037C38.4673 16.0925 38.6158 16.3892 38.6158 16.7042V22.9636C38.6158 23.2778 38.4681 23.5737 38.2171 23.7626L29.7254 30.1539C29.0664 30.65 28.124 30.1798 28.124 29.355V27.3436C28.124 27.0285 28.2725 26.7319 28.5248 26.5431L34.1095 22.3628C34.3618 22.174 34.5103 21.8773 34.5103 21.5622L34.5103 15.1282Z" fill="#EB7D7D"/>
    <path d="M14.895 16.1234C15.5544 15.6299 16.4943 16.1004 16.4943 16.924V18.9419C16.4943 19.257 16.3458 19.5536 16.0935 19.7425L10.5087 23.9228C10.2565 24.1116 10.108 24.4083 10.108 24.7233L10.108 31.1573C10.108 31.9809 9.1681 32.4514 8.50875 31.9579L6.4031 30.3818C6.15095 30.193 6.00245 29.8965 6.00234 29.5815L6.00017 23.2823C6.00006 22.9671 6.14858 22.6703 6.40093 22.4814L14.895 16.1234Z" fill="#EB7D7D"/>
    <path d="M33.3698 12.8412C33.3698 12.5261 33.2213 12.2295 32.9691 12.0407L22.8261 4.44842C22.4709 4.18257 21.9831 4.18251 21.6278 4.44827L6.40097 15.8397C6.14859 16.0285 6 16.3252 6 16.6404L6 19.4232C6 20.2455 6.93729 20.7164 7.59699 20.2254L21.6902 9.738C22.0453 9.47371 22.532 9.47439 22.8864 9.73968L27.6428 13.3C27.8951 13.4888 28.0436 13.7855 28.0436 14.1005V23.4279C28.0436 24.2515 28.9835 24.722 29.6428 24.2284L32.9691 21.7387C33.2213 21.5499 33.3698 21.2532 33.3698 20.9381V12.8412Z" fill="#BE5A6F"/>
    <path d="M11.2458 33.6567C11.2458 33.9718 11.3944 34.2684 11.6466 34.4573L21.7901 42.0498C22.145 42.3155 22.6325 42.3158 22.9877 42.0504L38.2142 30.6765C38.4669 30.4877 38.6157 30.1907 38.6157 29.8753V27.0747C38.6157 26.2524 37.6784 25.7815 37.0187 26.2725L22.9255 36.7599C22.5704 37.0242 22.0837 37.0235 21.7293 36.7582L16.9729 33.1979C16.7206 33.0091 16.5721 32.7125 16.5721 32.3974V23.07C16.5721 22.2464 15.6322 21.7759 14.9729 22.2695L11.6466 24.7592C11.3944 24.948 11.2458 25.2447 11.2458 25.5598V33.6567Z" fill="#BE5A6F"/>
    <defs>
      <radialGradient id="coinstone_0001000_paint0" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.2144 23.1228) rotate(90) scale(6.41406 6.64244)">
        <stop stopColor="#FEDDAE"/>
        <stop offset="1" stopColor="#FFCD88"/>
      </radialGradient>
    </defs>
  </svg>
);

export default CoinstoneLogo;
