// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-characters-anvil-tsx": () => import("./../../../src/pages/characters/anvil.tsx" /* webpackChunkName: "component---src-pages-characters-anvil-tsx" */),
  "component---src-pages-characters-devana-tsx": () => import("./../../../src/pages/characters/devana.tsx" /* webpackChunkName: "component---src-pages-characters-devana-tsx" */),
  "component---src-pages-characters-jin-tsx": () => import("./../../../src/pages/characters/jin.tsx" /* webpackChunkName: "component---src-pages-characters-jin-tsx" */),
  "component---src-pages-characters-kaine-tsx": () => import("./../../../src/pages/characters/kaine.tsx" /* webpackChunkName: "component---src-pages-characters-kaine-tsx" */),
  "component---src-pages-characters-maruk-tsx": () => import("./../../../src/pages/characters/maruk.tsx" /* webpackChunkName: "component---src-pages-characters-maruk-tsx" */),
  "component---src-pages-characters-tsx": () => import("./../../../src/pages/characters.tsx" /* webpackChunkName: "component---src-pages-characters-tsx" */),
  "component---src-pages-foundry-tsx": () => import("./../../../src/pages/foundry.tsx" /* webpackChunkName: "component---src-pages-foundry-tsx" */),
  "component---src-pages-homebrew-tsx": () => import("./../../../src/pages/homebrew.tsx" /* webpackChunkName: "component---src-pages-homebrew-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-items-tsx": () => import("./../../../src/pages/items.tsx" /* webpackChunkName: "component---src-pages-items-tsx" */),
  "component---src-pages-spells-tsx": () => import("./../../../src/pages/spells.tsx" /* webpackChunkName: "component---src-pages-spells-tsx" */),
  "component---src-templates-markdown-template-tsx": () => import("./../../../src/templates/MarkdownTemplate.tsx" /* webpackChunkName: "component---src-templates-markdown-template-tsx" */),
  "component---src-templates-spell-page-template-tsx": () => import("./../../../src/templates/SpellPageTemplate.tsx" /* webpackChunkName: "component---src-templates-spell-page-template-tsx" */)
}

