import { TBreadcrumb } from "services/LayoutService";
import { filterServiceInitialState } from "services/DataFilterService"

export const initialState = {
  auth: {
    loggedIn: false,
    user: null as any,
    error: null as AuthError,
  },
  layout: {
    root: "app" as "auth" | "app",
    isMobileLayout: true,
    isExpanded: false,
    isMobileOpened: false,
    isHeaderLarge: false,
    breadcrumbs: [] as TBreadcrumb[],
    dialog: {
      open: false,
      title: "",
      content: "",
      cancel: true,
      actionLabel: "",
      action: () => {},
    },
    snackbar: {
      open: false,
      variant: "success",
      message: "Success",
    },
  },
  spells: filterServiceInitialState,
};

export type IAppState = typeof initialState;
export interface IState {
  app: IAppState;
  internal: TObjectAny;
}
