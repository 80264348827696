import {
  constructBitArray,
  filterActiveData,
  filterBitarray,
  IBitArray,
} from "utils/bitUtils";
import {
  flattenDimensionData,
  paginateData,
  updateActiveBuckets,
} from "utils/filterUtils";
import { getDimensionObject, IDimension, TBucket } from "models/Dimension";
import { capitaliseEachWordCase } from "utils/functions";

export interface TBreadcrumb {
  label: string;
  url?: string;
}

export const filterServiceInitialState = {
  loading: false,
  fetched: false,
  all: [] as any[],
  flatDimData: [] as any[],
  bitarray: new Uint8Array(0) as IBitArray,
  filtered: [] as any[],
  active: [] as any[],
  dimensions: {} as Record<string, IDimension>,
  sort: {
    dim: "",
    direction: "asc",
  },
  page: {
    limit: 10,
    current: 0,
    total: 0,
  },
};

type IAppGetState = () => { [key: string]: any };
type IAppUpdateState = (producer: (s: any) => void, comment: string) => void;

class DataFilterService {
  stateKey: string;
  comment: (m: string) => string;
  appGetState: IAppGetState;
  appUpdateState: IAppUpdateState;
  constructor(
    stateKey = "data" as string,
    appGetState: IAppGetState,
    appUpdateState: IAppUpdateState
  ) {
    this.stateKey = stateKey;
    this.appGetState = appGetState;
    this.appUpdateState = appUpdateState;
    this.comment = (m: string) =>
      `${capitaliseEachWordCase(this.stateKey)}Service::${m}`;
  }
  // private appNextState = appNextState;

  public setLoading(loading: boolean) {
    this.appUpdateState(
      (s) => (s[this.stateKey].loading = loading),
      this.comment("toggleLoading")
    );
  }

  public async reset(data: any[], dimensions: IDimension[], override?: false) {
    const {
      [this.stateKey]: { all },
    } = this.appGetState();
    if (override || all.length === 0) {
      this.setLoading(true);
      const bitarray = constructBitArray({
        dimensionsLength: dimensions.length,
        dataLength: data.length,
      });
      const flatDimData = flattenDimensionData(data, dimensions);
      this.appUpdateState(
        (s) =>
          (s[this.stateKey] = {
            ...s[this.stateKey],
            fetched: true,
            all: data,
            dimensions: getDimensionObject(dimensions),
            flatDimData,
            bitarray,
          }),
        this.comment("resetAllData")
      );
      await this.updateDimensionFilter({});
      await this.applyPagination({});
    }
  }

  public async updateFilter({
    key,
    bucket,
  }: {
    key: string;
    bucket: TBucket | null;
  }) {
    const {
      [this.stateKey]: { dimensions },
    } = this.appGetState();
    const dimension = dimensions[key];
    if (dimension) {
      this.setLoading(true);
      await this.updateDimensionFilter({ bucket, dimension });
      await this.applyPagination({});
    }
    const {
      [this.stateKey]: { dimensions: newDim },
    } = this.appGetState();
    return newDim;
  }

  public bulkUpdateFilters = async (
    filters: (
      | {
          key: string;
          bucket: TBucket | null;
        }
      | undefined
    )[]
  ) => {
    const {
      [this.stateKey]: { dimensions },
    } = this.appGetState();
    if (filters.length > 0) {
      this.setLoading(true);
      for (let i = 0; i < filters.length; i++) {
        const f = filters[i];
        if (f) {
          const dimension = dimensions[f.key];
          await this.updateBitArray({ bucket: f.bucket, dimension });
        }
      }
      const {
        [this.stateKey]: { bitarray, all },
      } = this.appGetState();

      await this.applyDataFilters({ bitarray, all });
      await this.applyPagination({});
    }
    const {
      [this.stateKey]: { dimensions: newDim },
    } = this.appGetState();
    return newDim;
  };

  public async nextPage() {
    const {
      [this.stateKey]: {
        page: { current },
      },
    } = this.appGetState();
    await this.applyPagination({ num: current + 1 });
  }
  public async prevPage() {
    const {
      [this.stateKey]: {
        page: { current },
      },
    } = this.appGetState();
    await this.applyPagination({ num: current - 1 });
  }
  public async firstPage() {
    await this.applyPagination({ num: 0 });
  }
  public async lastPage() {
    const {
      [this.stateKey]: {
        page: { total },
      },
    } = this.appGetState();
    await this.applyPagination({ num: total-1 });
  }
  public async updatePageLimit(limit: number) {
    console.log(limit);
    await this.applyPagination({ limit });
  }

  //Private methods

  private async updateDimensionFilter({
    bucket,
    dimension,
  }: {
    bucket?: TBucket | null;
    dimension?: IDimension;
  }) {
    const {
      [this.stateKey]: { bitarray, all },
    } = this.appGetState();
    if (dimension) {
      const newBitarray = await this.updateBitArray({dimension,bucket})
      return this.applyDataFilters({
        bitarray: newBitarray,
        all,
      });
    } else {
      return this.applyDataFilters({
        bitarray,
        all,
      });
    }
  }

  private async updateBitArray({
    bucket,
    dimension,
  }: {
    bucket?: TBucket | null;
    dimension: IDimension;
  }) {
    const {
      [this.stateKey]: { bitarray, flatDimData },
    } = this.appGetState();
    const newBuckets = updateActiveBuckets(bucket, dimension);
    this.appUpdateState(
      (s) =>
        (s[this.stateKey].dimensions[dimension.key] = {
          ...dimension,
          buckets: newBuckets,
        }),
      this.comment("updatedDimension")
    );
    const newBitarray = await filterBitarray({
      dimension: { ...dimension, buckets: newBuckets },
      bitarray,
      values: flatDimData,
      length: flatDimData.length,
    });
    this.appUpdateState(
      (s) =>
        (s[this.stateKey] = { ...s[this.stateKey], bitarray: newBitarray }),
      this.comment("updatedBitArray")
    );
    return newBitarray;
  }

  private async applyDataFilters({
    bitarray,
    all,
  }: {
    bitarray: IBitArray;
    all: any[];
  }) {
    const filtered = filterActiveData({
      data: all,
      bitarray,
      length: all.length,
    });
    this.appUpdateState(
      (s) => (s[this.stateKey] = { ...s[this.stateKey], filtered, bitarray }),
      this.comment("appliedFilters")
    );
  }

  private async applyPagination({
    num,
    limit: newLimit,
  }: {
    num?: number;
    limit?: number;
  }) {
    const {
      [this.stateKey]: { page, filtered },
    } = this.appGetState();
    const limit = newLimit || page.limit;
    const paginated = paginateData({
      data: filtered,
      next: num !== undefined ? num : page.current,
      limit,
    });
    if (paginated) {
      this.appUpdateState(
        (s) =>
          (s[this.stateKey] = {
            ...s[this.stateKey],
            active: paginated.data,
            page: {
              current: paginated.current,
              limit,
              total: Math.ceil(filtered.length/limit),
            },
          }),
        this.comment("appliedPagination")
      );
      this.setLoading(false);
    }
  }
}

// const filterService = new DataFilterService();
export { DataFilterService };
