import {
  EXACT_MATCH_ARRAY_DIM,
  EXACT_MATCH_DIM,
  FACET_MATCH_ARRAY_DIM,
  FUZZY_MATCH_DIM,
  IDimension,
} from "models/Dimension";
import {
  exactArrayMatch,
  facetMatchArray,
  facetMatchString,
  fuzzyMatch,
} from "utils/filterUtils";
export type IBitArray = Uint8Array | Uint16Array | Uint32Array;

export const createBitIndices = (dimensions: IDimension[]): IDimension[] => {
  const masks = { 0: 0 };

  return dimensions.map((f: any) => {
    const m = masks[0];
    const id = (~m & (m + 1)) >>> 0;
    masks[0] |= id;

    return {
      bitIndex: id,
      ...f,
    };
  });
};

export const constructBitArray = ({
  dimensionsLength,
  dataLength,
}: {
  dimensionsLength: number;
  dataLength: number;
}): IBitArray => {
  switch (true) {
    case dimensionsLength <= 8:
      return new Uint8Array(dataLength);
    case dimensionsLength <= 16:
      return new Uint16Array(dataLength);
    case dimensionsLength <= 32:
      return new Uint32Array(dataLength);
    default:
      throw new Error("too many filters!!");
  }
};

export const filterFunctionBitarray = async ({
  match,
  testFunction,
  bitarray: currentBitarray,
  values,
  length,
  bitIndex,
}: {
  testFunction: (match: any, value: any) => boolean;
  bitIndex: number;
  match: any;
  bitarray: IBitArray;
  values: any[];
  length: number;
}) => {
  let i,
    x,
    zero = (bitIndex + 1) * -1;

  const bitarray = currentBitarray.slice(0);

  for (i = 0; i < length; ++i) {
    //check if bitIndex already in index of filter bitarray ^ (XOR) apply test function
    if (
      // @ts-ignore
      !(currentBitarray[i] & bitIndex) ^
      !!(x = testFunction(match, values[i][bitIndex]))
    ) {
      //If passes test && not already in index, then add
      if (x) bitarray[i] &= zero;
      // XOR if fails test && is in index, then remove
      else bitarray[i] |= bitIndex;
    }
  }
  return bitarray;
};

export const filterExactBitarray = async ({
  match,
  bitarray: currentBitarray,
  values,
  length,
  bitIndex,
}: {
  match: string | string[];
  bitarray: IBitArray;
  values: any[];
  length: number;
  bitIndex: number;
}) => {
  let i,
    x,
    zero = (bitIndex + 1) * -1;

  const bitarray = currentBitarray.slice(0);

  for (i = 0; i < length; ++i) {
    //check if bitIndex already in index of filter bitarray ^ (XOR) apply test function
    if (
      // @ts-ignore
      !(currentBitarray[i] & bitIndex) ^ !!(x = "" + values[i] === match)
    ) {
      //If passes test && not already in index, then add
      if (x) bitarray[i] &= zero;
      // XOR if fails test && is in index, then remove
      else bitarray[i] |= bitIndex;
    }
  }
  return bitarray;
};

export const clearDimensionFilter = async ({
  bitarray,
  length,
  bitIndex,
}: {
  bitarray: IBitArray;
  length: number;
  bitIndex: number;
}) => {
  let i,
    zero = (bitIndex + 1) * -1;

  for (i = 0; i < length; ++i) {
    if (bitarray[i] & bitIndex) {
      bitarray[i] &= zero;
    }
  }
  return bitarray;
};

export const filterActiveData = ({
  data,
  bitarray,
  length,
}: {
  data: string[];
  bitarray: IBitArray;
  length: number;
}) => {
  let array = [];
  for (let i = 0; i < length; i++) {
    if (bitarray[i] === 0) {
      array.push(data[i]);
    }
  }
  return array;
};
export const filterBitarray = async ({
  dimension,
  ...rest
}: {
  bitarray: IBitArray;
  values: any[];
  length: number;
  dimension: IDimension;
}) => {
  const { buckets: match, bitIndex } = dimension;
  if (match === null || match.length === 0) {
    return clearDimensionFilter({
      bitIndex,
      bitarray: rest.bitarray,
      length: rest.length,
    });
  }
  switch (dimension.match) {
    case EXACT_MATCH_DIM:
      return filterExactBitarray({
        match,
        bitIndex,
        ...rest,
      });

    case EXACT_MATCH_ARRAY_DIM:
      return filterFunctionBitarray({
        match,
        bitIndex,
        testFunction: exactArrayMatch,
        ...rest,
      });
    case FACET_MATCH_ARRAY_DIM:
      return filterFunctionBitarray({
        match,
        bitIndex,
        testFunction: facetMatchArray,
        ...rest,
      });
    case FUZZY_MATCH_DIM:
      return filterFunctionBitarray({
        match,
        bitIndex,
        testFunction: fuzzyMatch,
        ...rest,
      });
    default:
      //default = FACET_MATCH_DIM
      return filterFunctionBitarray({
        match,
        bitIndex,
        testFunction: facetMatchString,
        ...rest,
      });
  }
};
