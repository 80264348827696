import React from "react";
import createIcon from "./createIcon";

const IconBrew = createIcon(
  <React.Fragment>
    <path d="M10.5 2C8.56 2 6.85 3.24 6.23 5.08C4.36 5.44 3 7.09 3 9C3 11.21 4.79 13 7 13V21H18V19H21C21.55 19 22 18.55 22 18V10C22 9.45 21.55 9 21 9H19V7C19 4.79 17.21 3 15 3H13.32C12.5 2.35 11.53 2 10.5 2ZM10.5 4C11.29 4 12.03 4.37 12.5 5H15C16.11 5 17 5.9 17 7H13C11 7 10.32 8.13 9.5 9.63C8.68 11.13 7 11 7 11C5.89 11 5 10.11 5 9C5 7.9 5.89 7 7 7H8V6.5C8 5.12 9.12 4 10.5 4ZM18 11H20V17H18V11Z" />
  </React.Fragment>,
  "Brew"
);

export default IconBrew;
