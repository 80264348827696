import { ISpell } from "models/Spell";
import {
  EXACT_MATCH_ARRAY_DIM,
  EXACT_MATCH_DIM,
  FUZZY_MATCH_DIM,
  IDimension,
  TBucket,
} from "models/Dimension";

export interface IFitlerDefinition {
  type?: string;
  match?: "fuzzy" | "exact";
}

export const updateActiveBuckets = (bucket: any, dimension: IDimension) => {
  //if dim should only be single text string return bucket
  if (
    dimension.match &&
    [EXACT_MATCH_DIM, FUZZY_MATCH_DIM, EXACT_MATCH_ARRAY_DIM].includes(
      dimension.match
    )
  ) {
    return bucket;
  }
  let filters = dimension.buckets ? [...dimension.buckets] : [];
  //if null clear all filters
  if (bucket === null) {
    return [];
    //if bucket is already an array, then just replace
  } else if (Array.isArray(bucket)) {
    return bucket;
  } else {
    const index = filters.indexOf(bucket);
    //add if key doesn't exist in filter, or remove if it does
    if (index === -1) {
      filters.push(bucket);
    } else {
      filters.splice(index, 1);
    }
  }
  return filters;
};

export const oldupdateActiveBuckets = (
  bucket: any,
  oldBuckets?: TBucket,
  type?: string
) => {
  let filters = oldBuckets ? [...oldBuckets] : [];
  //if null clear all filters
  if (bucket === null) {
    filters = [];
  } else if (typeof bucket === "object" && type !== "composite") {
    filters = bucket;
  } else {
    //if exclusive then ignore prev filters
    if (type === "exclusive") {
      filters = [bucket];
    } else {
      var index = filters.indexOf(bucket);
      //add if key doesn't exist in filter, or remove if it does
      if (index === -1) {
        filters.push(bucket);
      } else {
        filters.splice(index, 1);
      }
    }
  }
  return filters;
};

export const applyFilters = async (
  data: ISpell[],
  filters: Record<string, string[]>,
  filterDefinitions: Record<string, IFitlerDefinition>
) => {
  return data.filter((d) => filterFunc(d, filters, filterDefinitions));
};

const filterFunc = (
  s: ISpell,
  filters: Record<string, string[]>,
  filterDefinitions: Record<string, IFitlerDefinition>
) => {
  const keys = Object.keys(filters);
  let keep = true;
  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    const f = filters[k];
    const matchType = filterDefinitions[k]?.match;
    // @ts-ignore
    const v = deepObjectAccessor(s, k);
    if (!testFilter(v, f, matchType)) {
      keep = false;
      break;
    }
  }
  return keep;
};
export const deepObjectAccessor = (obj: TObjectAny, keyString: string): any => {
  return keyString.split(".").reduce((o, k) => o?.[k], obj);
};

export const testFilter = (
  value: any,
  filter: string[],
  matchType = "exclusive"
) => {
  let v = ["number", "boolean"].includes(typeof value) ? `${value}` : value;

  if (matchType === "fuzzy" && typeof v === "string") {
    return fuzzyMatchArray(value, filter);
  }
  return (
    (Array.isArray(v) && filter.some((r) => v.includes(r))) ||
    filter.includes(v)
  );
};

export const exactArrayMatch = (match: string, values: string[]) => {
  return values.includes(match);
};
export const facetMatchString = (matches: string[], value: string) => {
  return matches.includes("" + value);
};
export const facetMatchArray = (matches: string[], values: string[]) => {
  return matches.some((r) => values.includes(r));
};

export const fuzzyMatch = (match: string, value: string) => {
  const v = ("" + value).toLowerCase();
  const queryParts = match
    .toLowerCase()
    .replace(/[()]/g, "")
    .split(" ")
    .map((q) => q.trim());

  return queryParts.reduce((test, q) => {
    return v.includes(q) && test;
  }, true);
};

const fuzzyMatchArray = (match: string, values: string[]) => {
  const p = match.toLowerCase();
  return values.reduce((testAll, query) => {
    const queryParts = query
      .toLowerCase()
      .replace(/[()]/g, "")
      .split(" ")
      .map((q) => q.trim());

    return queryParts.reduce((test, q) => {
      return p.indexOf(q) > -1 && test;
    }, true);
  }, true);
};

export const paginateData = ({
  data,
  next: possibleNext,
  limit,
}: {
  data: any[];
  next: number;
  limit: number;
}) => {
  const total = data.length;
  if (total === 0) {
    return {
      data: data,
      current: 0,
    };
  }
  const next = Math.max(possibleNext, 0);
  const start = next * limit;
  if (start <= total) {
    const end = Math.min(start + limit, total);
    const paginatedData = data.slice(start, end);
    return {
      data: paginatedData,
      current: next,
    };
  }
  return false;
};

export const flattenDimensionData = (data: any[], dimensions: IDimension[]) => {
  return data.map((d) => {
    return dimensions.reduce((obj, k) => {
      return { ...obj, [k.bitIndex]: deepObjectAccessor(d, k.key) };
    }, {} as Record<string, any>);
  });
};

export const dimensionsToParamsFormat = (dimensions: Record<string, IDimension>) => {
  return Object.keys(dimensions)
    .filter((d) => {
      const b = dimensions[d].buckets || [];
      return b !== null && b.length > 0;
    })
    .reduce((r, d) => ({ ...r, [d]: dimensions[d].buckets }), {});
};

export const diffParamsAndDimensions = (dimensions: any, params: any) => {
  const dimKeys = Object.keys(dimensions).sort();
  const paramKeys = Object.keys(params).sort();
  const dimToRemove = dimKeys
    .filter((x) => !paramKeys.includes(x))
    .map((d) => ({ key: d, bucket: null }));
  const dimToAdd = paramKeys
    .filter((x) => !dimKeys.includes(x))
    .map((d) => ({ key: d, bucket: params[d] }));

  const diffIntersection = dimKeys
    .filter((a) => paramKeys.includes(a))
    .map((x) => {
      const d = dimensions[x] as string | string[];
      let p = params[x];
      if (typeof d === "string") {
        if (d !== p) {
          return { key: x, bucket: p };
        }
      } else {
        p = typeof p === "string" ? [p] : p;
        if (
          p.length !== d.length ||
          !d.every((val, index) => val === p[index])
        ) {
          return { key: x, bucket: p };
        }
      }
    })
    .filter((x) => !!x);

  return [...dimToRemove, ...dimToAdd, ...diffIntersection];
};
