import React from "react";
import createIcon from "./createIcon";

const IconQuadrant = createIcon(
  <React.Fragment>
    <path d="M8.10299 5.75736L11.3628 2.49751C12.0262 1.83416 12.9738 1.83416 13.6372 2.49751L16.897 5.75736L12.5 10.1638L8.10299 5.75736ZM18.2426 7.10299L13.8362 11.5L18.2426 15.897L21.5025 12.6372C22.0711 11.9738 22.0711 11.0262 21.5025 10.3628L18.2426 7.10299ZM6.75736 15.897L11.1638 11.5L6.75736 7.10299L3.49751 10.3628C2.83416 11.0262 2.83416 11.9738 3.49751 12.6372L6.75736 15.897ZM12.5 12.8362L8.10299 17.2426L11.3628 20.5025C12.0262 21.1658 12.9738 21.1658 13.6372 20.5025L16.897 17.2426L12.5 12.8362Z"/>
  </React.Fragment>,
  "Quadrant"
);

export default IconQuadrant;
