/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// import withStoreProvider from "./src/store/withStoreProvider"
import withRootProviders from "components/withRootProviders"
import withPageComponents from "./src/components/withPageComponents"

export const wrapRootElement = withRootProviders

export const wrapPageElement = withPageComponents
