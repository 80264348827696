export const debounce = (fn: Function, ms: number = 0) => {
  let timeoutId: any;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(null, args), ms);
  };
};

const arrayKeyBy = (array: any[], key: string) =>
  (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});

export const keyBy = (collection: any[] | TObjectAny, key: string) => {
  const c = collection || {};
  return Array.isArray(c)
    ? arrayKeyBy(c, key)
    : arrayKeyBy(Object.values(c), key);
};

export const formatNumber = (value: number | string, decimal: number = 0) => {
  if (typeof value === "string" || typeof value === "undefined") return value;
  let parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (parts[1]) {
    parts[1] = parts[1].slice(0, decimal);
  }
  return decimal === 0 ? parts[0] : parts.join(".");
};

export const kebabCase = (str: string) => cleanCase(str);
export const camelCase = (str: string) =>
  cleanCase(str).replace(/[-]+(\w|$)/g, (_, p1) => p1.toUpperCase());
export const pascalCase = (str: string) => {
  let temp = camelCase(str);
  return temp.charAt(0).toUpperCase() + temp.slice(1);
};
export const snakeCase = (str: string) => cleanCase(str).replace(/-/g, "_");

const cleanCase = (str: string) =>
  str
    //convert non-alphanumeric characters to dash (possibly too aggressive)
    .replace(/'[^a-zA-Z0-9]'/g, "-") // alternative // str = str.replace(/[_\s.'-,]/g,'-')
    //add dash in front of capitals
    .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2")
    //remove duplicate dashes
    .replace(/([-])\1+/g, "-")
    //remove dash from front and end of str
    .replace(/^[-]/, "")
    .replace(/[-]$/, "")
    //lowercase string
    .toLowerCase();

export const capitaliseEachWordCase = (str: string) =>
  str
    .split(" ")
    .reduce((s, p) => s + p.charAt(0).toUpperCase() + p.slice(1) + " ", "")
    .trim();

export const cleanOrderedLabel = (label: string | number): string => {
  const s = `${label}`;
  let parts = s.split("@");
  if (parts.length === 1) {
    return s;
  }
  parts.shift();
  return parts.join("@");
};

export const generateUEID = () => {
  let first: string | number = (Math.random() * 46656) | 0;
  let second: string | number = (Math.random() * 46656) | 0;
  first = ("000" + first.toString(36)).slice(-3);
  second = ("000" + second.toString(36)).slice(-3);
  return first + second;
};
