import React, { useMemo } from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import RuneBackground from "components/layouts/appLayout/RuneBackground";
import { layoutService } from "services/LayoutService";
import { useBreakpoint } from "react-laag";
import { useSelector } from "react-redux";
import { IState } from "store";
import AppLayout from "components/layouts/AppLayout";
import "scss/global.scss";
import styles from "./RootLayout.module.scss";

interface Props {
  children: any;
  variant?: "lighthouse" | "underwater" | "moon" | "bottle" | "kraken";
}

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };
const animations = {
  initial: { opacity: 0.0 },
  animate: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { ...transition, duration: 1.5 },
  },
};

const RootLayout = ({ children }: Props) => {
  const isMobile = useBreakpoint(992);
  const layout = useSelector((s: IState) => s.app.layout.root);
  useMemo(() => {
    if (typeof window !== "undefined") {
      layoutService.setMobileLayout(isMobile);
    }
  }, [isMobile]);

  return (
    <motion.div className={cx(styles.root)} {...animations}>
      <RuneBackground layout={layout} />
      <AppLayout>{children}</AppLayout>
    </motion.div>
  );
};

export default RootLayout;
