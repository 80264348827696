/**
 * AppLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import cx from "classnames";
import { IState } from "store";
import NavBar from "./appLayout/NavBar";
import { layoutService } from "services/LayoutService";
import styles from "./AppLayout.module.scss";

interface Props {
  children: any;
}

const AppLayout = (props: Props) => {
  const {
    isMobileLayout,
    isExpanded,
    isMobileOpened,
    root: rootLayoutState,
  } = useSelector((s: IState) => s.app.layout);

  const toggleMobileNav = () => {
    layoutService.expandMobileSidebarToggle(!isMobileOpened);
  };

  if (rootLayoutState !== "app") {
    layoutService.setRootLayout("app");
  }

  return (
    <motion.div
      className={cx(styles.root, isMobileOpened && styles.mobileOpened)}
      variants={variants}
      initial="hidden"
      animate={isMobileOpened && isMobileLayout ? "visible" : "hidden"}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className={styles.contentWrapper}
        variants={containerVariants}
      >
        {isMobileOpened && isMobileLayout && (
          <div className={styles.contentOverlay} onClick={toggleMobileNav} />
        )}
        {props.children}
      </motion.div>
      <NavBar
        isMobile={isMobileLayout}
        isExpanded={isExpanded}
        className={styles.nav}
        isMobileOpened={isMobileOpened}
        toggleMobileNav={toggleMobileNav}
      />
    </motion.div>
  );
};

const transition = { duration: 0.5, ease: "easeInOut" };

const variants = {
  visible: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  hidden: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: 1 as -1,
      delayChildren: 0.2,
    },
  },
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { ...transition, duration: 1.5 },
  },
};

const containerVariants = {
  visible: {
    transform: "translateX(250px) translateY(50px)",
  },
  hidden: {
    transform: "translateX(0px) translateY(0px)",
  },
};

export default AppLayout;
