import React from "react";
import { useHover, ToggleLayer, Arrow } from "react-laag";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import styles from "./Tooltip.module.scss";

interface Props {
  children: React.ReactNode;
  /** The content to be displayed within the tooltip */
  content: React.ReactNode;
  className?: string;
  /** The direction of the tooltip relative to the element (will move if there isn't enough space) */
  direction?:
    | "TOP_LEFT"
    | "TOP_CENTER"
    | "TOP_RIGHT"
    | "BOTTOM_LEFT"
    | "BOTTOM_CENTER"
    | "BOTTOM_RIGHT"
    | "LEFT_TOP"
    | "LEFT_CENTER"
    | "LEFT_BOTTOM"
    | "RIGHT_TOP"
    | "RIGHT_CENTER"
    | "RIGHT_BOTTOM"
    | "CENTER";

  /** Should the arrow show */
  arrow?: boolean;

  /** How much should the tooltip be offset from the element */
  offset?: number;

  /** Override hover trigger and force open */
  // open?: boolean;

  /** Framer motion animation object */
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
}

export const Tooltip = ({
  children,
  content,
  className,
  direction = "TOP_CENTER",
  arrow = true,
  offset = 4,
  animation,
}: Props) => {
  const [show, hoverProps] = useHover({ delayEnter: 300, delayLeave: 200 });

  return (
    <ToggleLayer
      isOpen={show}
      fixed
      placement={{ anchor: direction, autoAdjust: true, snapToAnchor: true, triggerOffset: offset }}
      renderLayer={props => (
        <TooltipWrapper
          className={className}
          animation={animation}
          {...props}
          arrow={arrow}
        >
          {content}
        </TooltipWrapper>
      )}
    >
      {({ triggerRef }) => (
        <span ref={triggerRef} {...hoverProps}>
          {children}
        </span>
      )}
    </ToggleLayer>
  );
};

interface WrapperProps {
  isOpen: boolean;
  layerProps: {
    ref: (instance: HTMLDivElement | null) => void;
    style: object;
  };
  layerSide: "top" | "left" | "right" | "bottom" | "center" | undefined;
  arrowStyle: object;
  children: React.ReactNode;
  className?: string;
  arrow?: boolean;
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
}

const TooltipWrapper = ({
  isOpen,
  layerProps,
  layerSide,
  arrowStyle,
  children,
  className,
  animation,
  arrow,
}: WrapperProps) => {
  const animateOptions = animation || {
    initial: {
      opacity: 0,
      scale: 0.8,
      y: layerSide === "top" ? -8 : 8,
    },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: layerSide === "top" ? -8 : 8,
    },
    transition: {
      type: "spring",
      damping: 30,
      stiffness: 500,
    },
  };
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={layerProps.ref}
          className={cx(className, styles.tooltip)}
          style={layerProps.style}
          {...animateOptions}
        >
          <div>{children}</div>

          {arrow && <Arrow layerSide={layerSide} style={arrowStyle} />}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Tooltip;
