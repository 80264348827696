import React from "react";
import { layoutService } from "services/LayoutService";
import cx from "classnames";
import Logo from "components/ui/logo/CoinstoneLogo";
import BrandName from "components/ui/logo/BrandName";
import NavItem from "./NavItem";
import { AnimatePresence, motion } from "framer-motion";
import NavBarExpander from "./NavIBarExpander";
import {
  sidebarNav,
  TSidebarNav,
  TSidebarNavItem,
} from "components/sidebarNav";
import styles from "./NavBar.module.scss";
import { useLocation } from "@reach/router";

const variants = {
  visible: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 as -1 },
  },
};

interface Props {
  className?: string;
  isMobile: boolean;
  isExpanded: boolean;
  isMobileOpened: boolean;
  toggleMobileNav: () => void;
}

const NavBar = ({
  className,
  isMobile,
  isExpanded,
  isMobileOpened,
  toggleMobileNav,
}: Props) => {
  const { pathname } = useLocation();
  let initial = "hidden";
  const mobileExpand = isMobile ? isMobileOpened : false;

  const expandToggleSidebar = () => {
    layoutService.expandSidebarToggle(!isExpanded);
  };

  const toggleMobileSidebar = () => {
    if (isMobile) {
      toggleMobileNav();
    }
  };
  const items = generateNavItems({
    items: sidebarNav,
    isExpanded,
    pathname,
    isMobile,
  });

  return (
    <motion.nav
      className={cx(
        className,
        styles.root,
        (isExpanded || mobileExpand) && styles.expanded
      )}
    >
      <div className={styles.sticky}>
        <div className={styles.logoContainer}>
          <Logo className={styles.logo} />
          <BrandName className={styles.logoText} />
        </div>

        <motion.div
          className={styles.navItemContainer}
          variants={variants}
          initial={initial}
          animate={
            !isMobile || isExpanded || mobileExpand ? "visible" : "hidden"
          }
          onClick={toggleMobileSidebar}
        >
          <AnimatePresence exitBeforeEnter>{items}</AnimatePresence>
        </motion.div>
        {!isMobile && (
          <NavBarExpander
            className={styles.expander}
            isExpanded={isExpanded}
            onClick={expandToggleSidebar}
          />
        )}
      </div>
    </motion.nav>
  );
};

const generateNavItems = ({
  items,
  isExpanded,
  pathname,
  isMobile,
}: {
  items: TSidebarNav;
  isExpanded: boolean;
  pathname: string;
  isMobile: boolean;
}) => {
  const authorizedNav = items.reduce((n, s) => {
    const items = s.items;
    if (items.length) {
      n.push({ ...s, items });
    }
    return n;
  }, [] as TSidebarNav);

  return authorizedNav.map((section, i) => (
    <React.Fragment key={`${i}:${section.sectionName}`}>
      {section.sectionName && (
        <li
          className={cx(styles.listSection, {
            [styles.sectionExpanded]: isExpanded,
          })}
        >
          {section.sectionName}
        </li>
      )}
      {section.items.map((item, k) => (
        <NavItem
          key={`${k}:${item.name}`}
          text={item.name}
          active={isItemActive(item, pathname)}
          displayTooltip={!isExpanded && !isMobile}
          {...item}
        />
      ))}
    </React.Fragment>
  ));
};

const isItemActive = (
  item: TSidebarNavItem,
  pathname: string | string[]
): boolean => {
  const url = item.to || item.href || "";
  return url === "/" ? url === pathname : pathname.indexOf(url) === 0;
};

export default NavBar;
